import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOGYzMWFlNDE1MDJlZmNjMzNmMWI4YjMzYmUxMDVjMWNjZDEyODBiNjcwNWUwM2UxMWQzNjNmNzBjNGYzZmVmODdkYTkzMDE1NTIwZmMyYjAiLCJpYXQiOjE3MjY2NTc5MTAuMzI2Njc2LCJuYmYiOjE3MjY2NTc5MTAuMzI2NjgyLCJleHAiOjE3MjY3NDQzMTAuMzIyMjA0LCJzdWIiOiI2MyIsInNjb3BlcyI6W119.0ed-qv1X8nIsLmV2POcXhc29VM4xZ2aYhRhELTjb-OEsAuH1gAU59N0myJK11xj9HWuPLZIhxm2tFdAyCjIK2mqJDqoVh3kjWcGA4-289NxSVBUHXHx5x3Mr2JKd95BvYYazE0mSN6GpLTWhm6X2qCLe-oPjw0WpcsDqHW2_g3WcHmCOYbx0eVUdi4mc1cNAulqJ2fLZcQ-AAa91tRFdK13cqZ27Y0bE-BRR3HzfLZqh4Xw4WbG77ABKPX88cGMYOHlhyHGniU2QGJvezaZBShZ111AdCZeugyOLwM7QQ4hrGMgwBSGRFsfmWMFEX1ker_Vmy9GycISRX75HQ8DixC0SjOXd7KC3CU2D20_EL4_9qRktGNEdv0tNRgTrTckeccTwQXQuLrVbE-pM_ipq1s4S0kKxyW0FDeY8rkVtexNWtVLVvBAKDu1kIIOlTZd5Fngt6-_T9utSsGVk0xSjvQCicBffTp9hbQnrcqkyRZxJRIewxIDFCq5lpNlOoE-7sF-ivjx8JTJg53mI8vOZi9dHzxrUEJQw0n2aXfkRTvackoLK0Fe3r8_gSF9m2veITYacI5qJdPP55yyvzWC3TsDXYyRGL086Oqc91iUPzYA1NRJVvCqAtpFVo6WrGk2vpIEyy2R-GLXzaZvwzYVpZcg7VLky20erutMit8XUh5Q',
    'Access-api-key': "eyJpdiI6InJOUWthQkhMMlJqRGVndHFiWFV3SXc9PSIsInZhbHVlIjoiWnliQ2ZOUTFWaGdUTXA4VUlUdHp5UT09IiwibWFjIjoiMmE0ZTYxZTQxM2RjNzc5YjhkMzMxMzA3MGUyMDgzNTQ2ZjBiYWE0NTM4ZWQ3Nzg5OWIwOGYxOWMzOTFiNjNmYyIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6ImpscFBLeFJmaG5YRDAvTWkwWnp1eUE9PSIsInZhbHVlIjoiSjVDTGZmMkhLUDBKRTkzK2FaMHhqQT09IiwibWFjIjoiYjMwMjFlMDMyYTZhYmQ2N2QyNGExNzZlZTY5YTQ5OTlhNzMyNzUyOWJmN2NiMmRhN2FmZDM1ODA2NTZhN2MxMCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}